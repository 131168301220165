<template>
  <div class="mb-4">
    <CRow class="mb-0">
      <CCol lg="2">
        <CInput v-model="adjustment_id" placeholder="Employment ID" />
      </CCol>
      <CCol lg="2">
        <v-select
          label="name"
          :options="personnelList.data"
          :reduce="(item) => item.id"
          v-model="personnel_id"
          placeholder="Employment Type"
        >
        </v-select>
      </CCol>
      <CCol lg="2">
        <CInput v-model="p_name" placeholder="Name" />
      </CCol>
      <CCol lg="2">
        
      </CCol>
      <CCol lg="2"> </CCol>
      <CCol lg="2" class="float-right">
        <CButton size="sm" block color="info" @click="search"
          ><i class="icon-magnifier"></i> Search</CButton
        >
      </CCol>
    </CRow>
    <CRow>
      <CCol lg="2">
        
      </CCol>
      <CCol lg="2">
        
      </CCol>
      <CCol lg="2">
        
      </CCol>

      <CCol lg="2">
        
      </CCol>
      <CCol lg="2"> </CCol>
      <CCol lg="2" class="float-right">
        <CButton size="sm" block @click="clearSearch" color="info"
          >Clear Search</CButton
        >
      </CCol>
    </CRow>
  </div>
</template>

<script>
import config from "../../config.js";
import axios from "../../axios";
import style from "../../style.css";
import vSelect from "vue-select";
import Datepicker from "vuejs-datepicker";
import "vue-select/dist/vue-select.css";
export default {
  name: "AdjustmentSearch",
  data() {
    return {
      adjustment_id: "",
      booking_no: "",
      p_name: "",
      destination: "",
      delivery_date_from: "",
      delivery_date_to: "",
      personnel_id: "",
      status: "",

      personnelList: {
        data: [],
      },
    };
  },
  mounted() {
    this.getPersonnel();
  },
  components: { vSelect, Datepicker },
  methods: {
    search() {
      const data = {
        adjustment_id: this.adjustment_id,
        booking_no: this.booking_no,
        p_name: this.p_name,
        destination: this.destination,
        delivery_date_from: this.delivery_date_from,
        delivery_date_to: this.delivery_date_to,
        personnel_id: this.personnel_id,
        status: this.status,
      };
      this.$emit("adjustment-search-query", data);
    },
    clearSearch() {
      this.adjustment_id = "";
      this.booking_no = "";
      this.p_name = "";
      this.destination = "";
      this.delivery_date_from = "";
      this.delivery_date_to = "";
      this.personnel_id = "";
      this.status == "";
      // this.maker_model_id = "";
      // this.year = "";
      const data = {
        adjustment_id: this.adjustment_id,
        booking_no: this.booking_no,
        p_name: this.p_name,
        destination: this.destination,
        delivery_date_from: this.delivery_date_from,
        delivery_date_to: this.delivery_date_to,
        personnel_id: this.personnel_id,
        status: this.status,
        // maker_model_id: this.maker_model_id,
        // year: this.year,
      };
      this.$emit("adjustment-search-query", data);
    },

    getPersonnel() {
      axios
        .get(config.api_path + "/reference/personnel-list")
        .then((response) => {
          this.personnelList = response.data;
        });
    },
  },
};
</script>

<style scoped></style>
